import React, { useEffect } from 'react';
import { WidgetLazyLoader } from 'components/common/WidgetLazyLoader';
import { configuration } from 'config/constants';
import { addChangeCookieConsentsEvent, setCookieConsentDone } from 'services/CookieConsent';

declare global {
    namespace React {
        namespace JSX {
            interface IntrinsicElements {
                'sgwt-mini-footer': {
                    'id': string;
                    'no-border': boolean;
                    'mode': string;
                    'contact-us': string;
                };
            }
        }
    }
}

export const Footer: React.FC = () => {
    useEffect(() => {
        return addChangeCookieConsentsEvent(() => {
            setCookieConsentDone();
        });
    }, []);

    return <footer className="sgbs-footer-light w-100 d-flex border-top">
        <div className="flex-grow-1">
            <WidgetLazyLoader script={`${configuration.widgetCdnBaseUrl}/widgets/sgwt-mini-footer/v4/sgwt-mini-footer.js`}>
                <sgwt-mini-footer
                    id="sgm-mini-footer"
                    no-border
                    mode="sg-markets"
                    contact-us="{'mail':'sg-markets@sgcib.com'}"
                />
            </WidgetLazyLoader>
        </div>
    </footer>;
};
