import classNames from 'classnames';
import * as React from 'react';
import { FC } from 'react';

export interface IProps {
    text?: string;
    className?: string;
    visible?: boolean;
    useChildrenAsText?: boolean;
    style?: React.CSSProperties;
    children?: React.ReactNode;
}

export const Loading: FC<IProps> = ({ text, className, style, visible = true, children, useChildrenAsText = false }) => {
    if (!visible) {
        return <>{children}</>;
    }
    return (
        <div className={classNames('d-flex align-items-center', className)} style={style}>
            <div className="spinner-border me-2" role="status" aria-hidden="true"></div>
            <strong> {useChildrenAsText ? children : text || 'Loading'} </strong>
        </div>
    );
};

export interface IServiceLoaderProps {
    visible?: boolean;
    children?: React.ReactNode;
    light?: boolean;
}

export const ServiceLoader: FC<IServiceLoaderProps> = ({ visible = true, children, light = false }) => {
    if (!visible) {
        return children;
    }

    if (light) {
        return <div className="service-loader d-flex flex-column position-absolute">
            <div className="spinner spinner-lg"></div>
        </div>;
    }

    return <div className="service-loader d-flex flex-column position-absolute">
        <div className="service-loader-logo"></div>
        <div className="progress progress-sm progress-container">
            <div className="progress-bar animated-progress" role="progressbar" />
        </div>
    </div>;
};