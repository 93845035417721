import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ServiceLoader } from 'components/common/Loading';
import { Layout } from 'components/Layout';
import { RoutePaths } from './RoutePaths';
import { ProtectedRoute } from './ProtectedRoute';

const HomeLayout = lazy(() => import('components/Pages/Home/HomeLayout'));
const NotAuthorized = lazy(() => import('components/Pages/Errors/NotAuthorized'));
const NotFound = lazy(() => import('components/Pages/Errors/NotFound'));
const Form = lazy(() => import('components/Pages/Form/Form'));

export const AppRoutes: React.FC = () => {
    return <Layout>
        <Suspense fallback={<div className="d-flex justify-content-center"><ServiceLoader /></div>}>
            <Routes>
                <Route path={RoutePaths.Home.route} element={<ProtectedRoute><HomeLayout /></ProtectedRoute>} />
                <Route path={RoutePaths.EditReco.route} element={<ProtectedRoute privatePrivilegeRequired={true}><Form /></ProtectedRoute>} />
                <Route path={RoutePaths.CreateReco.route} element={<ProtectedRoute privatePrivilegeRequired={true}><Form /></ProtectedRoute>} />
                <Route path={RoutePaths.ViewReco.route} element={<ProtectedRoute><Form /></ProtectedRoute>} />
                <Route path={RoutePaths.Errors.NotAuthorized.route} element={<NotAuthorized />} />
                <Route path={RoutePaths.Errors.NotAuthorizedContactUs.route} element={<NotAuthorized isDisplayTryAgain={false} />} />
                <Route path={RoutePaths.Errors.NotFound.route} element={<NotFound />} />
            </Routes>
        </Suspense>
    </Layout>;
};
